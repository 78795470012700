import { FC, useCallback, useContext } from 'react';
import Link from 'next/link';
import IconButton from '@mui/material/IconButton';

import useLocalised from 'customHooks/useLocalised/useLocalised';
import {
  navigationPaths,
  SIGN_IN_DIALOG,
  MOBILE_MENU_DIALOGUE,
  JOIN_US_DIALOG_STEP_1,
  colorConstants,
} from 'data/constants';
import { join_us, sign_in } from 'data/translationKeys';
import NavigationItem from 'components/NavigationItem/NavigationItem';
import LittleEmperorsIcon from 'components/icons/LittleEmperorsIcon';
import { DialogueContext } from 'customHooks/useDialogue/useDialogue';
import BurgerMenuIcon from 'components/icons/BurgerMenuIcon';
import LittleEmperorsTextIcon from 'components/icons/LittleEmperorsTextIcon';
import Button, { LINK_BUTTON_TYPE } from 'components/Button/Button';
import BurgerMenuCloseIcon from 'components/icons/BurgerMenuCloseIcon';

interface IHeader {}
export const Header: FC<IHeader> = () => {
  const join_us_text = useLocalised(join_us);
  const sign_in_text = useLocalised(sign_in);
  const { openDialogue, closeDialogue, dialogueState } = useContext(DialogueContext);
  const isMobileMenuOpen = dialogueState.presentedDialogueType === MOBILE_MENU_DIALOGUE;

  const handleClickOnSignIn = useCallback(() => {
    openDialogue(SIGN_IN_DIALOG);
  }, [openDialogue]);

  const handleClickOnJoinUs = useCallback(() => {
    openDialogue(JOIN_US_DIALOG_STEP_1);
  }, [openDialogue]);

  const handleBurgerMenuClick = useCallback(() => {
    openDialogue(MOBILE_MENU_DIALOGUE);
  }, [openDialogue]);

  return (
    <div className="header-component">
      <div className="header-component__container">
        <div className="header-left">
          <NavigationItem
            title="Hotels"
            path={navigationPaths.OUR_HOTELS}
            className="header-navigation-item"
          />
          <NavigationItem
            title="Membership"
            path={navigationPaths.MEMBERSHIP}
            className="header-navigation-item"
          />
          <NavigationItem
            title="Gift cards"
            path={navigationPaths.E_GIFT_CARD}
            className="header-navigation-item"
          />
          <NavigationItem
            title="Discover MyLER"
            path={navigationPaths.DISCOVER_MYLER}
            className="header-navigation-item"
          />
        </div>
        <div className="burger-icon">
          {!isMobileMenuOpen && (
            <IconButton onClick={handleBurgerMenuClick} title="mobile-menu">
              <BurgerMenuIcon />
            </IconButton>
          )}
          {isMobileMenuOpen && (
            <IconButton onClick={closeDialogue} title="mobile-menu">
              <BurgerMenuCloseIcon />
            </IconButton>
          )}
        </div>
        <div className="header-center">
          <Link href={navigationPaths.HOMEPAGE}>
            <a area-label="homepage">
              <LittleEmperorsIcon color={colorConstants.brandColor} />
            </a>
          </Link>
          <Link href={navigationPaths.HOMEPAGE}>
            <a area-label="homepage">
              <LittleEmperorsTextIcon color={colorConstants.brandColor} />
            </a>
          </Link>
        </div>
        <div className="header-rigth">
          <Button
            title={join_us_text}
            onClick={handleClickOnJoinUs}
            buttonType={LINK_BUTTON_TYPE}
            className={'header-join_us_text_button'}
          />
          <Button
            title={sign_in_text}
            onClick={handleClickOnSignIn}
            buttonType={LINK_BUTTON_TYPE}
            className={'header-navigation-item'}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
