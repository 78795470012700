import { FC } from 'react';

const BurgerMenuCloseIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <g clip-path="url(#A)" fill-rule="evenodd" fill="#fff">
        <path d="M3.497 3.493a.5.5 0 0 1 .707 0l16.305 16.305a.5.5 0 0 1-.707.707L3.497 4.2a.5.5 0 0 1 0-.707z" />
        <path d="M20.502 3.491a.5.5 0 0 1 0 .707L4.198 20.503a.5.5 0 1 1-.707-.707L19.795 3.491a.5.5 0 0 1 .707 0z" />
      </g>
      <defs>
        <clipPath id="A">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BurgerMenuCloseIcon;
